var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c(
        "Card",
        [
          _c(
            "Row",
            { staticClass: "operation" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "primary", icon: "md-add" },
                  on: { click: _vm.addMenu },
                },
                [_vm._v("添加子节点")]
              ),
              _c(
                "Button",
                { attrs: { icon: "md-add" }, on: { click: _vm.addRootMenu } },
                [_vm._v("添加顶部菜单")]
              ),
              _c(
                "Button",
                { attrs: { icon: "md-trash" }, on: { click: _vm.delAll } },
                [_vm._v("批量删除")]
              ),
              _c(
                "Dropdown",
                { on: { "on-click": _vm.handleDropdown } },
                [
                  _c(
                    "Button",
                    [
                      _vm._v(" 更多操作 "),
                      _c("Icon", { attrs: { type: "md-arrow-dropdown" } }),
                    ],
                    1
                  ),
                  _c(
                    "DropdownMenu",
                    { attrs: { slot: "list" }, slot: "list" },
                    [
                      _c("DropdownItem", { attrs: { name: "refresh" } }, [
                        _vm._v("刷新"),
                      ]),
                      _c("DropdownItem", { attrs: { name: "expandOne" } }, [
                        _vm._v("收合所有"),
                      ]),
                      _c("DropdownItem", { attrs: { name: "expandTwo" } }, [
                        _vm._v("仅展开一级"),
                      ]),
                      _c("DropdownItem", { attrs: { name: "expandThree" } }, [
                        _vm._v("仅展开两级"),
                      ]),
                      _c("DropdownItem", { attrs: { name: "expandAll" } }, [
                        _vm._v("展开所有"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "i-switch",
                {
                  staticStyle: { "margin-left": "5px" },
                  attrs: { size: "large" },
                  model: {
                    value: _vm.strict,
                    callback: function ($$v) {
                      _vm.strict = $$v
                    },
                    expression: "strict",
                  },
                },
                [
                  _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                    _vm._v("级联"),
                  ]),
                  _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                    _vm._v("单选"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "Row",
            { attrs: { type: "flex", justify: "start", gutter: 16 } },
            [
              _c(
                "Col",
                { attrs: { sm: 8, md: 8, lg: 8, xl: 6 } },
                [
                  _c("Alert", { attrs: { "show-icon": "" } }, [
                    _vm._v(" 当前选择编辑： "),
                    _c("span", { staticClass: "select-title" }, [
                      _vm._v(_vm._s(_vm.editTitle)),
                    ]),
                    _vm.form.id
                      ? _c(
                          "a",
                          {
                            staticClass: "select-clear",
                            on: { click: _vm.cancelEdit },
                          },
                          [_vm._v("取消选择")]
                        )
                      : _vm._e(),
                  ]),
                  _c("Input", {
                    attrs: {
                      suffix: "ios-search",
                      placeholder: "输入菜单名搜索",
                      clearable: "",
                    },
                    on: { "on-change": _vm.search },
                    model: {
                      value: _vm.searchKey,
                      callback: function ($$v) {
                        _vm.searchKey = $$v
                      },
                      expression: "searchKey",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "tree-bar",
                      style: { maxHeight: _vm.maxHeight },
                    },
                    [
                      _c("Tree", {
                        ref: "tree",
                        attrs: {
                          data: _vm.data,
                          "show-checkbox": "",
                          render: _vm.renderContent,
                          "check-strictly": !_vm.strict,
                        },
                        on: {
                          "on-select-change": _vm.selectTree,
                          "on-check-change": _vm.changeSelect,
                        },
                      }),
                      _vm.loading
                        ? _c("Spin", { attrs: { size: "large", fix: "" } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { sm: 16, md: 16, lg: 16, xl: 9 } },
                [
                  _c(
                    "Form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": 110,
                        rules: _vm.formValidate,
                      },
                    },
                    [
                      _c(
                        "FormItem",
                        { attrs: { label: "类型", prop: "type" } },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.type == -1,
                                  expression: "form.type == -1",
                                },
                              ],
                            },
                            [
                              _c("Icon", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: {
                                  type: "ios-navigate-outline",
                                  size: "16",
                                },
                              }),
                              _c("span", [_vm._v("顶部菜单")]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.type == 0,
                                  expression: "form.type == 0",
                                },
                              ],
                            },
                            [
                              _c("Icon", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: {
                                  type: "ios-list-box-outline",
                                  size: "16",
                                },
                              }),
                              _c("span", [_vm._v("页面菜单")]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.type == 1,
                                  expression: "form.type == 1",
                                },
                              ],
                            },
                            [
                              _c("Icon", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: {
                                  type: "md-radio-button-on",
                                  size: "16",
                                },
                              }),
                              _c("span", [_vm._v("操作按钮")]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.form.type == 0 || _vm.form.type == 1
                        ? _c(
                            "FormItem",
                            { attrs: { label: "上级菜单", prop: "pid" } },
                            [
                              _c("TreeSelect", {
                                attrs: { data: _vm.parentData, clearable: "" },
                                model: {
                                  value: _vm.form.parentId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "parentId", $$v)
                                  },
                                  expression: "form.parentId",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == -1 || _vm.form.type == 0
                        ? _c(
                            "FormItem",
                            { attrs: { label: "名称", prop: "title" } },
                            [
                              _c("Input", {
                                model: {
                                  value: _vm.form.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "title", $$v)
                                  },
                                  expression: "form.title",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == 1
                        ? _c(
                            "FormItem",
                            {
                              staticClass: "block-tool",
                              attrs: { label: "名称", prop: "title" },
                            },
                            [
                              _c(
                                "Tooltip",
                                {
                                  attrs: {
                                    placement: "right",
                                    content: "操作按钮名称不得重复",
                                  },
                                },
                                [
                                  _c("Input", {
                                    model: {
                                      value: _vm.form.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "title", $$v)
                                      },
                                      expression: "form.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == 0
                        ? _c(
                            "FormItem",
                            { attrs: { label: "路径", prop: "path" } },
                            [
                              _c("Input", {
                                model: {
                                  value: _vm.form.path,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "path", $$v)
                                  },
                                  expression: "form.path",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == 1
                        ? _c(
                            "FormItem",
                            {
                              staticClass: "block-tool",
                              attrs: { label: "请求路径", prop: "path" },
                            },
                            [
                              _c(
                                "Tooltip",
                                {
                                  attrs: {
                                    placement: "right",
                                    "max-width": 230,
                                    transfer: "",
                                    content:
                                      "填写后端请求URL，后端将作权限拦截，若无可填写'无'或其他",
                                  },
                                },
                                [
                                  _c("Input", {
                                    model: {
                                      value: _vm.form.path,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "path", $$v)
                                      },
                                      expression: "form.path",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == 1
                        ? _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "按钮权限类型",
                                prop: "buttonType",
                              },
                            },
                            [
                              _c(
                                "Select",
                                {
                                  attrs: {
                                    placeholder: "请选择或输入搜索",
                                    filterable: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.buttonType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "buttonType", $$v)
                                    },
                                    expression: "form.buttonType",
                                  },
                                },
                                _vm._l(_vm.dictPermissions, function (item, i) {
                                  return _c(
                                    "Option",
                                    { key: i, attrs: { value: item.value } },
                                    [_vm._v(_vm._s(item.title) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == -1
                        ? _c(
                            "FormItem",
                            {
                              staticClass: "block-tool",
                              attrs: { label: "英文名", prop: "name" },
                            },
                            [
                              _c(
                                "Tooltip",
                                {
                                  attrs: {
                                    placement: "right",
                                    content: "需唯一",
                                  },
                                },
                                [
                                  _c("Input", {
                                    model: {
                                      value: _vm.form.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == 0
                        ? _c(
                            "FormItem",
                            {
                              staticClass: "block-tool",
                              attrs: { label: "路由英文名", prop: "name" },
                            },
                            [
                              _c(
                                "Tooltip",
                                {
                                  attrs: {
                                    placement: "right",
                                    content: "需唯一",
                                    transfer: "",
                                  },
                                },
                                [
                                  _c("Input", {
                                    model: {
                                      value: _vm.form.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == -1 || _vm.form.type == 0
                        ? _c(
                            "FormItem",
                            { attrs: { label: "图标", prop: "icon" } },
                            [
                              _c("icon-choose", {
                                model: {
                                  value: _vm.form.icon,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "icon", $$v)
                                  },
                                  expression: "form.icon",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == -1
                        ? _c(
                            "FormItem",
                            {
                              attrs: { label: "系统站内菜单", prop: "isMenu" },
                            },
                            [
                              _c(
                                "i-switch",
                                {
                                  attrs: { size: "large" },
                                  model: {
                                    value: _vm.form.isMenu,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isMenu", $$v)
                                    },
                                    expression: "form.isMenu",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "open" }, slot: "open" },
                                    [_vm._v("是")]
                                  ),
                                  _c(
                                    "span",
                                    { attrs: { slot: "close" }, slot: "close" },
                                    [_vm._v("否")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == -1
                        ? _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "显示左侧菜单",
                                prop: "showLeft",
                              },
                            },
                            [
                              _c(
                                "i-switch",
                                {
                                  attrs: { size: "large" },
                                  model: {
                                    value: _vm.form.showLeft,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "showLeft", $$v)
                                    },
                                    expression: "form.showLeft",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "open" }, slot: "open" },
                                    [_vm._v("是")]
                                  ),
                                  _c(
                                    "span",
                                    { attrs: { slot: "close" }, slot: "close" },
                                    [_vm._v("否")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == -1 && !_vm.form.isMenu
                        ? _c(
                            "FormItem",
                            { attrs: { label: "其他链接", prop: "url" } },
                            [
                              _c("Input", {
                                attrs: { placeholder: "Http链接或路由名" },
                                model: {
                                  value: _vm.form.url,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "url", $$v)
                                  },
                                  expression: "form.url",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == -1 && !_vm.form.isMenu
                        ? _c(
                            "FormItem",
                            {
                              attrs: { label: "打开方式", prop: "description" },
                            },
                            [
                              _c(
                                "Select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.form.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "description", $$v)
                                    },
                                    expression: "form.description",
                                  },
                                },
                                [
                                  _c(
                                    "Option",
                                    {
                                      attrs: {
                                        value: "direct",
                                        label: "直接跳转",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [_vm._v("直接跳转")]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#ccc" } },
                                        [_vm._v("其他链接填写完整链接")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "Option",
                                    {
                                      attrs: {
                                        value: "window",
                                        label: "新窗口打开",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [_vm._v("新窗口打开")]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#ccc" } },
                                        [_vm._v("其他链接填写完整链接")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "Option",
                                    {
                                      attrs: {
                                        value: "route",
                                        label: "站内路由",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [_vm._v("站内路由")]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#ccc" } },
                                        [_vm._v("其他链接填写Vue路由名")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == 0
                        ? _c(
                            "FormItem",
                            { attrs: { label: "前端组件", prop: "component" } },
                            [
                              _c("Input", {
                                attrs: {
                                  disabled: _vm.form.level == 1 ? true : false,
                                },
                                model: {
                                  value: _vm.form.component,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "component", $$v)
                                  },
                                  expression: "form.component",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.type == 0 && _vm.form.level == 2
                        ? _c(
                            "FormItem",
                            { attrs: { label: "第三方链接", prop: "url" } },
                            [
                              _c("Input", {
                                attrs: { placeholder: "http://" },
                                on: { "on-change": _vm.changeEditUrl },
                                model: {
                                  value: _vm.form.url,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "url", $$v)
                                  },
                                  expression: "form.url",
                                },
                              }),
                              _c("span", { staticClass: "url-remark" }, [
                                _vm._v(
                                  "前端组件需为 sys/monitor/monitor 时生效"
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "FormItem",
                        { attrs: { label: "排序值", prop: "sortOrder" } },
                        [
                          _c(
                            "Tooltip",
                            {
                              attrs: {
                                trigger: "hover",
                                placement: "right",
                                content: "值越小越靠前，支持小数",
                              },
                            },
                            [
                              _c("InputNumber", {
                                attrs: { max: 1000, min: 0 },
                                model: {
                                  value: _vm.form.sortOrder,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sortOrder", $$v)
                                  },
                                  expression: "form.sortOrder",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.level == 1
                        ? _c(
                            "FormItem",
                            {
                              staticClass: "block-tool",
                              attrs: { label: "始终显示", prop: "showAlways" },
                            },
                            [
                              _c(
                                "i-switch",
                                {
                                  attrs: { size: "large" },
                                  model: {
                                    value: _vm.form.showAlways,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "showAlways", $$v)
                                    },
                                    expression: "form.showAlways",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "open" }, slot: "open" },
                                    [_vm._v("是")]
                                  ),
                                  _c(
                                    "span",
                                    { attrs: { slot: "close" }, slot: "close" },
                                    [_vm._v("否")]
                                  ),
                                ]
                              ),
                              _c(
                                "Tooltip",
                                {
                                  staticStyle: {
                                    display: "inline-block !important",
                                  },
                                  attrs: {
                                    content:
                                      "当设为不始终显示时，一级菜单下仅有一个子级菜单只会显示该子级菜单，避免用户多次点击",
                                    placement: "right",
                                    transfer: "",
                                    "max-width": "280",
                                  },
                                },
                                [
                                  _c("Icon", {
                                    staticStyle: {
                                      "margin-left": "10px",
                                      cursor: "pointer",
                                    },
                                    attrs: {
                                      type: "md-help-circle",
                                      size: "20",
                                      color: "#c5c5c5",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "FormItem",
                        { attrs: { label: "是否启用", prop: "status" } },
                        [
                          _c(
                            "i-switch",
                            {
                              attrs: {
                                size: "large",
                                "true-value": 0,
                                "false-value": -1,
                              },
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v("启用")]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v("禁用")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        [
                          _c(
                            "Button",
                            {
                              staticStyle: { "margin-right": "5px" },
                              attrs: {
                                loading: _vm.submitLoading,
                                type: "primary",
                                icon: "ios-create-outline",
                              },
                              on: { click: _vm.submitEdit },
                            },
                            [_vm._v("修改并保存 ")]
                          ),
                          _c("Button", { on: { click: _vm.handleReset } }, [
                            _vm._v("重置"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            draggable: "",
            title: _vm.modalTitle,
            "mask-closable": false,
            width: 500,
            styles: { top: "30px" },
          },
          model: {
            value: _vm.menuModalVisible,
            callback: function ($$v) {
              _vm.menuModalVisible = $$v
            },
            expression: "menuModalVisible",
          },
        },
        [
          _c(
            "Form",
            {
              ref: "formAdd",
              attrs: {
                model: _vm.formAdd,
                "label-width": 110,
                rules: _vm.formValidate,
              },
            },
            [
              _vm.showParent
                ? _c(
                    "div",
                    [
                      _c("FormItem", { attrs: { label: "上级节点：" } }, [
                        _vm._v(_vm._s(_vm.parentTitle)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c("FormItem", { attrs: { label: "类型", prop: "type" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formAdd.type == -1,
                        expression: "formAdd.type == -1",
                      },
                    ],
                  },
                  [
                    _c("Icon", {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { type: "ios-navigate-outline", size: "16" },
                    }),
                    _c("span", [_vm._v("顶部菜单")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formAdd.type == 0,
                        expression: "formAdd.type == 0",
                      },
                    ],
                  },
                  [
                    _c("Icon", {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { type: "ios-list-box-outline", size: "16" },
                    }),
                    _c("span", [_vm._v("页面菜单")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formAdd.type == 1,
                        expression: "formAdd.type == 1",
                      },
                    ],
                  },
                  [
                    _c("Icon", {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { type: "md-radio-button-on", size: "16" },
                    }),
                    _c("span", [_vm._v("操作按钮")]),
                  ],
                  1
                ),
              ]),
              _vm.form.type == 0 || _vm.form.type == 1
                ? _c(
                    "FormItem",
                    { attrs: { label: "上级菜单", prop: "pid" } },
                    [
                      _c("TreeSelect", {
                        attrs: { data: _vm.parentData, clearable: "" },
                        model: {
                          value: _vm.formAdd.parentId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formAdd, "parentId", $$v)
                          },
                          expression: "formAdd.parentId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == -1 || _vm.formAdd.type == 0
                ? _c(
                    "FormItem",
                    { attrs: { label: "名称", prop: "title" } },
                    [
                      _c("Input", {
                        model: {
                          value: _vm.formAdd.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formAdd, "title", $$v)
                          },
                          expression: "formAdd.title",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == 1
                ? _c(
                    "FormItem",
                    {
                      staticClass: "block-tool",
                      attrs: { label: "名称", prop: "title" },
                    },
                    [
                      _c(
                        "Tooltip",
                        {
                          attrs: {
                            placement: "right",
                            content: "操作按钮名称不得重复",
                          },
                        },
                        [
                          _c("Input", {
                            model: {
                              value: _vm.formAdd.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formAdd, "title", $$v)
                              },
                              expression: "formAdd.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == 0
                ? _c(
                    "FormItem",
                    { attrs: { label: "路径", prop: "path" } },
                    [
                      _c("Input", {
                        model: {
                          value: _vm.formAdd.path,
                          callback: function ($$v) {
                            _vm.$set(_vm.formAdd, "path", $$v)
                          },
                          expression: "formAdd.path",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == 1
                ? _c(
                    "FormItem",
                    {
                      staticClass: "block-tool",
                      attrs: { label: "请求路径", prop: "path" },
                    },
                    [
                      _c(
                        "Tooltip",
                        {
                          attrs: {
                            placement: "right",
                            "max-width": 230,
                            transfer: "",
                            content:
                              "填写后端请求URL，后端将作权限拦截，若无可填写'无'或其他",
                          },
                        },
                        [
                          _c("Input", {
                            model: {
                              value: _vm.formAdd.path,
                              callback: function ($$v) {
                                _vm.$set(_vm.formAdd, "path", $$v)
                              },
                              expression: "formAdd.path",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == 1
                ? _c(
                    "FormItem",
                    { attrs: { label: "按钮权限类型", prop: "buttonType" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            placeholder: "请选择或输入搜索",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.formAdd.buttonType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAdd, "buttonType", $$v)
                            },
                            expression: "formAdd.buttonType",
                          },
                        },
                        _vm._l(_vm.dictPermissions, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == -1
                ? _c(
                    "FormItem",
                    {
                      staticClass: "block-tool",
                      attrs: { label: "英文名", prop: "name" },
                    },
                    [
                      _c(
                        "Tooltip",
                        { attrs: { placement: "right", content: "需唯一" } },
                        [
                          _c("Input", {
                            model: {
                              value: _vm.formAdd.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formAdd, "name", $$v)
                              },
                              expression: "formAdd.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == 0
                ? _c(
                    "FormItem",
                    {
                      staticClass: "block-tool",
                      attrs: { label: "路由英文名", prop: "name" },
                    },
                    [
                      _c(
                        "Tooltip",
                        { attrs: { placement: "right", content: "需唯一" } },
                        [
                          _c("Input", {
                            model: {
                              value: _vm.formAdd.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formAdd, "name", $$v)
                              },
                              expression: "formAdd.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == -1 || _vm.formAdd.type == 0
                ? _c(
                    "FormItem",
                    { attrs: { label: "图标", prop: "icon" } },
                    [
                      _c("icon-choose", {
                        model: {
                          value: _vm.formAdd.icon,
                          callback: function ($$v) {
                            _vm.$set(_vm.formAdd, "icon", $$v)
                          },
                          expression: "formAdd.icon",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == -1
                ? _c(
                    "FormItem",
                    { attrs: { label: "系统站内菜单", prop: "isMenu" } },
                    [
                      _c(
                        "i-switch",
                        {
                          attrs: { size: "large" },
                          model: {
                            value: _vm.formAdd.isMenu,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAdd, "isMenu", $$v)
                            },
                            expression: "formAdd.isMenu",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "open" }, slot: "open" },
                            [_vm._v("是")]
                          ),
                          _c(
                            "span",
                            { attrs: { slot: "close" }, slot: "close" },
                            [_vm._v("否")]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == -1
                ? _c(
                    "FormItem",
                    { attrs: { label: "显示左侧菜单", prop: "showLeft" } },
                    [
                      _c(
                        "i-switch",
                        {
                          attrs: { size: "large" },
                          model: {
                            value: _vm.formAdd.showLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAdd, "showLeft", $$v)
                            },
                            expression: "formAdd.showLeft",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "open" }, slot: "open" },
                            [_vm._v("是")]
                          ),
                          _c(
                            "span",
                            { attrs: { slot: "close" }, slot: "close" },
                            [_vm._v("否")]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == -1 && !_vm.formAdd.isMenu
                ? _c(
                    "FormItem",
                    { attrs: { label: "其他链接", prop: "url" } },
                    [
                      _c("Input", {
                        attrs: { placeholder: "Http链接或路由名" },
                        model: {
                          value: _vm.formAdd.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.formAdd, "url", $$v)
                          },
                          expression: "formAdd.url",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == -1 && !_vm.formAdd.isMenu
                ? _c(
                    "FormItem",
                    { attrs: { label: "打开方式", prop: "description" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formAdd.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAdd, "description", $$v)
                            },
                            expression: "formAdd.description",
                          },
                        },
                        [
                          _c(
                            "Option",
                            { attrs: { value: "direct", label: "直接跳转" } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v("直接跳转")]
                              ),
                              _c("span", { staticStyle: { color: "#ccc" } }, [
                                _vm._v("其他链接填写完整链接"),
                              ]),
                            ]
                          ),
                          _c(
                            "Option",
                            { attrs: { value: "window", label: "新窗口打开" } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v("新窗口打开")]
                              ),
                              _c("span", { staticStyle: { color: "#ccc" } }, [
                                _vm._v("其他链接填写完整链接"),
                              ]),
                            ]
                          ),
                          _c(
                            "Option",
                            { attrs: { value: "route", label: "站内路由" } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v("站内路由")]
                              ),
                              _c("span", { staticStyle: { color: "#ccc" } }, [
                                _vm._v("其他链接填写Vue路由名"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == 0
                ? _c(
                    "FormItem",
                    { attrs: { label: "前端组件", prop: "component" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.formAdd.level == 1 ? true : false,
                        },
                        model: {
                          value: _vm.formAdd.component,
                          callback: function ($$v) {
                            _vm.$set(_vm.formAdd, "component", $$v)
                          },
                          expression: "formAdd.component",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formAdd.type == 0 && _vm.formAdd.level == 2
                ? _c(
                    "FormItem",
                    { attrs: { label: "第三方链接", prop: "url" } },
                    [
                      _c("Input", {
                        attrs: { placeholder: "http://" },
                        on: { "on-change": _vm.changeAddUrl },
                        model: {
                          value: _vm.formAdd.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.formAdd, "url", $$v)
                          },
                          expression: "formAdd.url",
                        },
                      }),
                      _c("span", { staticClass: "url-remark" }, [
                        _vm._v("前端组件需为 sys/monitor/monitor 时生效"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "FormItem",
                { attrs: { label: "排序值", prop: "sortOrder" } },
                [
                  _c(
                    "Tooltip",
                    {
                      attrs: {
                        trigger: "hover",
                        placement: "right",
                        content: "值越小越靠前，支持小数",
                      },
                    },
                    [
                      _c("InputNumber", {
                        attrs: { max: 1000, min: 0 },
                        model: {
                          value: _vm.formAdd.sortOrder,
                          callback: function ($$v) {
                            _vm.$set(_vm.formAdd, "sortOrder", $$v)
                          },
                          expression: "formAdd.sortOrder",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.formAdd.level == 1
                ? _c(
                    "FormItem",
                    {
                      staticClass: "block-tool",
                      attrs: { label: "始终显示", prop: "showAlways" },
                    },
                    [
                      _c(
                        "i-switch",
                        {
                          attrs: { size: "large" },
                          model: {
                            value: _vm.formAdd.showAlways,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAdd, "showAlways", $$v)
                            },
                            expression: "formAdd.showAlways",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "open" }, slot: "open" },
                            [_vm._v("是")]
                          ),
                          _c(
                            "span",
                            { attrs: { slot: "close" }, slot: "close" },
                            [_vm._v("否")]
                          ),
                        ]
                      ),
                      _c(
                        "Tooltip",
                        {
                          staticStyle: { display: "inline-block !important" },
                          attrs: {
                            content:
                              "当设为不始终显示时，一级菜单下仅有一个子级菜单只会显示该子级菜单，避免用户多次点击",
                            placement: "right",
                            transfer: "",
                            "max-width": "280",
                          },
                        },
                        [
                          _c("Icon", {
                            staticStyle: {
                              "margin-left": "10px",
                              cursor: "pointer",
                            },
                            attrs: {
                              type: "md-help-circle",
                              size: "20",
                              color: "#c5c5c5",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "FormItem",
                { attrs: { label: "是否启用", prop: "status" } },
                [
                  _c(
                    "i-switch",
                    {
                      attrs: {
                        size: "large",
                        "true-value": 0,
                        "false-value": -1,
                      },
                      model: {
                        value: _vm.formAdd.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formAdd, "status", $$v)
                        },
                        expression: "formAdd.status",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("启用"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("禁用"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.menuModalVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submitAdd },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }